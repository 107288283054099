import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/clients/web/packages/website/src/components/buttons/download-app-btn/download-app-btn.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/clients/web/packages/website/src/components/error/error.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/clients/web/packages/website/src/components/layout/layout/footer/footer.tsx");
;
import(/* webpackMode: "eager" */ "/src/clients/web/packages/website/src/components/layout/layout/main/main.module.scss");
;
import(/* webpackMode: "eager" */ "/src/clients/web/packages/website/src/components/layout/layout/navbar/downloads/downloads.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/clients/web/packages/website/src/components/layout/layout/navbar/links/links.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/clients/web/packages/website/src/components/layout/layout/navbar/logo/logo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/clients/web/packages/website/src/components/layout/layout/navbar/profile/profile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/clients/web/packages/website/src/components/layout/layout/navbar/wrapper/wrapper.tsx");
